import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import FadeIn from 'react-fade-in';
import { BrowserView, MobileView } from 'react-device-detect';

import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title,
        heading
      }
    }
  }
`
const AboutPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  return (
    <Layout className="page">
      <Seo title={frontmatter.heading} description={excerpt} />
      <Seo title={frontmatter.title} description={excerpt} />
      <div className="grids col-1 sm-2">
        <div className="about-image-wrapper">
          <MobileView>
            <StaticImage src="../images/profile.webp" alt="About Image" />
          </MobileView>
          <BrowserView>
            <StaticImage src="../images/profile.webp" alt="About Image" className="about-image" />
          </BrowserView>
        </div>
        <FadeIn delay="400">
          <div>
            <div className="wrapper about-content">
              <h1>{frontmatter.heading}</h1>
              <article>
                <p>
                  We live in a world where we digest an array of information from a variety of sources in seconds. At times, we may prefer to use heuristics, or mental shortcuts, when making decisions. I created “Assumptions Aside” to help others to take a second look at people, places, and things. I invite you to follow along with my journey into the world of health, education, labor, and everything in between.
                </p>
              </article>
              <h1>{frontmatter.title}</h1>
              <article dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </FadeIn>
      </div>
    </Layout>
  )
}

export default AboutPage
